import React from 'react'
import profile from '../../../assets/images/sarmad.jpeg'
import SocialLinks from '../../../components/SocialLinks'

const Profile = () => {

    return (
        <div className='lg:w-[380px] border-[1px] border-[#bbb] rounded-3xl px-6 py-8 sticky top-16'>
            <div className='flex justify-between mb-6'>
                <h2 className='text-white text-4xl font-bold text-[#03C988]'>Sarmad</h2>
                <h2 className='text-white leading-[20px]'>Full-Stack<br />Web Developer</h2>
            </div>
            <img src={profile} alt="" className="h-[220px] w-[220px] object-cover rounded-3xl mx-auto" />
            <div className='flex flex-col items-center'>
                <a href='mailto:sarmadawan35@gmail.com' className='text-white text-lg hover:text-blue-300 underline mt-4'>sarmadawan35@gmail.com</a>
                <h2 className='text-lg text-white mt-2 mb-4'>Faisalabad, Pakistan</h2>

                {/* SOCIAL MEDIA ICONS */}
                <SocialLinks />
                <a href='#contact'>
                    <button className='btn btn-wide btn-primary mt-4'>Hire Me</button>
                </a>
            </div>
        </div>
    )
}

export default Profile