import React from 'react'
import { GrFacebookOption, GrGithub, GrLinkedinOption,GrMail } from 'react-icons/gr'
import { FaWhatsapp } from 'react-icons/fa'
const SocialLinks = () => {
    return (
        <div className='flex gap-5 text-white text-xl'>
            <a target='_blank' rel="noreferrer" href='https://github.com/Sarmad-coder/' className='border-[1px] border-white p-[12px] rounded-full hover:border-primary hover:text-primary transition duration-300'><GrGithub /></a>
            <a target='_blank' rel="noreferrer" href='https://www.linkedin.com/in/sarmad-awan-73a4851b6/' className='border-[1px] border-white p-[12px] rounded-full hover:border-primary hover:text-primary transition duration-300'><GrLinkedinOption /></a>
            <a target='_blank' rel="noreferrer" href='https://www.facebook.com/profile.php?id=100004337118608/' className='border-[1px] border-white p-[12px] rounded-full hover:border-primary hover:text-primary transition duration-300'><GrFacebookOption /></a>
            <a target='_blank' rel="noreferrer" href='https://wa.me/+923343603636/' className='border-[1px] border-white p-[12px] rounded-full hover:border-primary hover:text-primary transition duration-300'><FaWhatsapp /></a>
            <a target='_blank' rel="noreferrer" href='mailto:sarmadawan35@gmail.com' className='border-[1px] border-white p-[12px] rounded-full hover:border-primary hover:text-primary transition duration-300'><GrMail /></a>
            
        </div>
    )
}

export default SocialLinks