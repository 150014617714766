import { lazy } from "react";
import PrivateRoute from "./PrivateRoute";

const { createBrowserRouter } = require("react-router-dom");
const { default: Main } = require("../layouts/Main");

const Home = lazy(() => import("../pages/Homepage/Homepage"));
const Blogs = lazy(() => import("../pages/Blogs/Blogs"));


const Projects = lazy(() => import("../pages/Projects/ProjectPage.js"));
const Resource = lazy(() => import("../pages/ResourcePage/Resources.js"));

const Errorpage = lazy(() => import("../pages/404/Errorpage"));


export const routes = createBrowserRouter([
    {
        path: '/',
        element: <Main />,
        children: [
            {
                path: '/',
                element: <Home />
            },
            {
                path: '/blogs',
                element: <Blogs />
            },
            {
                path: '/projects',
                element: <Projects />
            },
            {
                path: '/resource',
                element: <Resource />
            },
        ]
    },
    {
        path: '*',
        element: <Errorpage />
    },
])